import { datadogRum } from "@datadog/browser-rum";
import { LD_CONFIG } from "../global-constants";
import {
  ANALYTICS_ANONYMOUS_USER,
  AppFeatureFlag,
  ClientAnalyticsEventType,
  DEFAULT_FLAGS,
  FeatureFlagSet,
  LaunchDarklyDisabledOverrides,
  OrgRole,
  getDefaultFlagAttributes,
  getProductionEnvironment,
  typedObjectEntries,
} from "@hex/common";
import * as LDClient from "launchdarkly-js-client-sdk";
// eslint-disable-next-line no-restricted-imports
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual } from "lodash";
import microMemoize from "micro-memoize";
import { useCallback, useMemo } from "react";

import { useCurrentUser } from "../hooks/me/useCurrentUser";
import { useStore } from "../redux/hooks.js";
import { selectMe } from "../redux/slices/meSlice.js";

import { customerId, launchDarklyDisabled } from "./data";
import { trackEvent } from "./trackEvent.js";

// LD client for use outside the React component tree
let ldClient: LDClient.LDClient;
const initLdClient = (): void => {
  ldClient = LDClient.initialize(
    LD_CONFIG.clientSideID,
    LD_CONFIG.context ?? {},
    LD_CONFIG.options ?? {},
  );
};

/**
 * Subscribe to get current LD flag value and monitor changes
 * flagKey: LD flag key
 * flagCallback: called with the initial value when LD SDK is ready, and whenever the value changes
 *   returns the default flag value if the value from LD is undefined
 */
export const subscribeToFlag = (
  flagKey: keyof FeatureFlagSet,
  flagCallback: (value: unknown) => void,
): void => {
  if (!ldClient && !launchDarklyDisabled) initLdClient();
  ldClient?.on(`change:${flagKey}`, flagCallback);
  ldClient?.waitUntilReady().then(() => {
    const value = ldClient.variation(flagKey);
    flagCallback(value ?? DEFAULT_FLAGS[flagKey]);
  });
};

/**
 * Unsubscribe from LD flag value changes
 * flagKey: LD flag key
 * flagCallback: callback to unsubscribe
 */
export const unsubscribeToFlag = (
  flagKey: string,
  flagCallback: (value: unknown) => void,
): void => {
  ldClient?.off(flagKey, flagCallback);
};

const NO_FLAGS: Partial<FeatureFlagSet> = {};

// Just for testing/storybook purposes, shouldn't be used in prod
let ldManuallyDisabled = false;
export const setLaunchDarklyManuallyDisabled = (disabled: boolean): void => {
  ldManuallyDisabled = disabled;
};

const useFlagOverrides = (): Partial<FeatureFlagSet> => {
  const currentUser = useCurrentUser();
  return useMemo(() => {
    if ((!launchDarklyDisabled && !ldManuallyDisabled) || currentUser == null) {
      return NO_FLAGS;
    }

    return typedObjectEntries(LaunchDarklyDisabledOverrides).reduce<
      Partial<FeatureFlagSet>
    >((acc, overrideTuple) => {
      if (overrideTuple == null) {
        return acc;
      }

      const [flag, override] = overrideTuple;
      if (override == null) {
        return acc;
      }

      if (typeof override !== "function") {
        (acc as any)[flag] = override;
        return acc;
      }

      const value = override({
        email: currentUser.email,
        orgId: currentUser.org.id,
        ...getDefaultFlagAttributes({
          // TODO(GRVTY-1437): Deprecate stackId from me query
          stackId: currentUser.stackId,
          customerId,
          environment: getProductionEnvironment(window.location.hostname),
          orgRole: currentUser.orgRole,
        }),
      });
      if (value == null) {
        return acc;
      }

      (acc as any)[flag] = value;
      return acc;
    }, {});
  }, [currentUser]);
};

// memoize globally, instead of per-hook, because we expect inputs
// and outputs to be the same for all hooks, so this is more efficient
const makeFlagsObj = microMemoize(
  (
    flags: LDClient.LDFlagSet,
    flagOverrides: Partial<FeatureFlagSet>,
  ): FeatureFlagSet => {
    return {
      ...DEFAULT_FLAGS,
      ...flagOverrides,
      ...flags,
    };
  },
);

/**
 * For internal use only. Use @useGetHexFlag or @useHexFlag instead to access a
 * flag value in a component.
 */
const useHexFlags = (): FeatureFlagSet => {
  const flags = useFlags();
  const flagOverrides = useFlagOverrides();

  return makeFlagsObj(flags, flagOverrides);
};

/**
 * We store the value of the userId of the current LD context so that we know
 * when we've updated the user context in LD. Storing here instead of fetching
 * value from LD to reduce api calls.
 */
let ldUserId = ANALYTICS_ANONYMOUS_USER.id;

export const setLdUserIdentified = (userId: string): void => {
  ldUserId = userId;
};

const previousFeatureFlagValues: Partial<FeatureFlagSet> = {};

export type GetHexFlagFn = <K extends AppFeatureFlag>(
  flagKey: K,
) => FeatureFlagSet[K];

/**
 * Returns a callback for getting a feature flag value. This is useful if you
 * want to get the value of a feature flag outside of the main component
 * function body. If you just want to get the feature flag in the component
 * function body, use @useHexFlag hook instead.
 */
export function useGetHexFlag(): GetHexFlagFn {
  const flags = useHexFlags();
  const store = useStore();

  return useCallback(
    <K extends AppFeatureFlag>(flagKey: K) => {
      const flagValue = flags[flagKey];
      maybeTrackFlagUsage(
        flagKey,
        flagValue,
        selectMe(store.getState())?.orgRole,
      );
      return flagValue;
    },
    [flags, store],
  );
}

export function useHexFlag<K extends AppFeatureFlag>(
  flagKey: K,
): FeatureFlagSet[K] {
  const flags = useHexFlags();
  const flagValue = flags[flagKey];
  const store = useStore();
  maybeTrackFlagUsage(flagKey, flagValue, selectMe(store.getState())?.orgRole);
  // Send flag evaluations through RUM so they appear in session replay etc
  datadogRum.addFeatureFlagEvaluation(flagKey, flagValue);

  return flagValue;
}

/**
 * Tracks feature flag usage if feature flag has changed value.
 * We only track feature flag usage for logged in users.
 */
export function maybeTrackFlagUsage<K extends AppFeatureFlag>(
  flagKey: K,
  flagValue: FeatureFlagSet[K],
  orgRole?: OrgRole,
): void {
  if (
    flagValue != null &&
    !isEqual(previousFeatureFlagValues[flagKey], flagValue) &&
    ldUserId !== ANALYTICS_ANONYMOUS_USER.id
  ) {
    previousFeatureFlagValues[flagKey] = flagValue;
    trackEvent(ClientAnalyticsEventType.FEATURE_FLAG_EVALUATED, {
      featureFlagName: flagKey,
      orgRole,
      variation: JSON.stringify(flagValue),
    });
  }
}
